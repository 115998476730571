import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { app } from '../../firebase';
import axios from 'axios'; 
import ReactGA from "react-ga";
import './Billbot.css';
import reports from '../../asset/data/reports';
import load from '../../asset/COMMB Loading (1).gif';
import logo from '../../asset/logo-dot-white@4x.png';
import send from '../../asset/Send.png';
import mode from '../../asset/day-and-night (1).png';
import sound from '../../asset/sound.png';
import darkSound from '../../asset/dark-sound.png';
import search from '../../asset/search-interface-symbol.png';
import up from '../../asset/like.png';


const db = getFirestore(app);
const TRACKING_ID = "G-ST9BCR1WKQ";
ReactGA.initialize(TRACKING_ID, { debug_mode: true });

const Billbot = ({ darkMode, setBotState, setDarkMode }) => {
  // State variables
  const [success, setSuccess] = useState(null);
  const [messages, setMessages] = useState([]);
  const [pdfMessages, setpdfMessages] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [lang, setLang] = useState("EN");
  const [ttsEnabled, setTTSEnabled] = useState(false);
  const [allowTagClick, setAllowTagClick] = useState(true);
  const [typingIntervalId, setTypingIntervalId] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const conversationRef = useRef(null);

  // ========================================
  // Section: Functions
  // ========================================

  const sendMessage = async () => {
    setSuccess(null);
    setBotState('search');
    const sendButton = document.querySelector('.send-button');
    ReactGA.event({
      category: 'Chatbot Interaction',
      action: 'User Message',
      label: userMessage,
    });

    if (sendButton.classList.contains('inactive')) {
      return;
    }
    sendButton.classList.add('inactive');
  
    if (!userMessage) {
      return;
    }
    setAllowTagClick(false);
    setUserMessage("");
    const userMessageData = {
      msg_text: userMessage,
      type: 'user'
    };
    setMessages(prevMessages => [...prevMessages, userMessageData]);
    
    const url = lang === "FR" ? `https://ooh-gpt-2-0-tts-openai.onrender.com/sendMsgToOpenAI/fr` : 'https://ooh-gpt-2-0-tts-openai.onrender.com/sendMsgToOpenAI'; 
    const response = await axios.post(url, {
      userMessage: 'current user message: ' + userMessage,
    });

    const msg_index = messages.length;
    if (response.status === 200) {
      if (response.data.generatedResponse.includes('sorry, I am')) {
        setSuccess(false);
        setBotState('error');
      } else {
        setSuccess(true);
        setBotState('success');
      }

      setTimeout(() => {
        setBotState('none');
      }, 5000);
      const botMessage = lang === 'FR' ? response.data.generatedResponse + " Cliquez ci-dessous si vous souhaitez que je recherche davantage de résultats spécifiques dans la publication COMMB." : response.data.generatedResponse + " Click below if you would like me to look into the COMMB publication for more specific findings.";
      if (botMessage) {
        const ttsMessage = botMessage.replace('COMMB', 'comb');
        handleTTS(ttsMessage);
      }

      const botMessageData = {
        msg_text: botMessage,
        type: 'bot',
        timestamp: serverTimestamp(),
        showFeedback: false, 
        feedbackGiven: false 
      };
      const messageData = {
        msg_user: userMessage,
        msg_bot_initial: botMessage,
        ip_address: ipAddress,
        timestamp: serverTimestamp()
      };
      await addDoc(collection(db, "messages"), messageData);
      setMessages(prevMessages => [...prevMessages, botMessageData]);
      let index = 0;
      const typingInterval = setInterval(() => {
        if (index < botMessage.length) {
          const botMessageWithTyping = botMessage.substring(0, index + 1);
          const updatedBotMessageData = {
            ...botMessageData,
            msg_text: botMessageWithTyping
          };
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[prevMessages.length - 1] = updatedBotMessageData;
            return updatedMessages;
          });
          index++;
        } else {
          clearInterval(typingInterval);
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages];
            updatedMessages[prevMessages.length - 1].showFeedback = true; 
            return updatedMessages;
          });
          setAllowTagClick(true);
          sendButton.classList.remove('inactive');
          const btn = document.querySelector(`button#btn${messages.length+1}`);
          if (btn) {
            btn.classList.remove('hide');
            btn.classList.add('fade-in');
          } else {
            console.log(false);
          }
        }
      }, 15);
      setTypingIntervalId(typingInterval);
    } else {
      console.error('Error getting intial response.');
    }      
    handlePDFResponse(response, msg_index);
  };

  const handlePDFResponse = async (response, msg_index) => {
    const pdfUrl = 'https://ooh-gpt-2-0-tts-openai.onrender.com/rag';
    const requestData = {
      query: userMessage,
      filter: { language: "en-US" }
    };
  
    const headers = {
      'Content-Type': 'application/json'
    };
  
    const pdfResponse = await fetch(pdfUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData)
    });
  
    if (pdfResponse.status === 200) {
      const responseData = await pdfResponse.json();
  
      const oldUrl = 'https://assets.ctfassets.net/1l6si2vnlb2k/3VIYFbEhQT2JE5Njw6gfHE/90a96287f4027289a0c09fa094674838/Insights_Report__August__ENG__5_.pdf';
      const newUrl = 'https://assets.ctfassets.net/1l6si2vnlb2k/4aW2ctALGGCOkhPuoK1pVM/deab0fe5333cc2553607df77e975fedd/Insights_Report__August__ENG__5_.pdf';
  
      if (typeof responseData.sources === 'string') {
        let sourcesArray = responseData.sources.split(',').map(url => url.trim());
  
        sourcesArray = sourcesArray.map(source =>
          source === oldUrl ? newUrl : source
        );
  
        responseData.sources = sourcesArray.join(', ');
      }
  
      handleParaphrase(responseData, userMessage, response, msg_index);
    } else {
      console.error('Error getting seaplane response.');
    }
  };
  
  

  const handleParaphrase = async (pdfResponse, userMessage, response, msg_index) => {
    const url = lang === "FR" ? `https://ooh-gpt-2-0-tts-openai.onrender.com/paraphraseOpenAI/fr` : 'https://ooh-gpt-2-0-tts-openai.onrender.com/paraphraseOpenAI'; 
    const paraphraseResponse = await axios.post(url, {
      userMessage:  'user query: ' + userMessage + ' 1st response: ' + response.data.generatedResponse + ' 2nd response: ' + pdfResponse.response,
    });

    if (paraphraseResponse.status=== 200) {
      if (paraphraseResponse.data.generatedResponse.includes('I cannot') || 
          paraphraseResponse.data.generatedResponse.includes('I am unable to') ||  
          paraphraseResponse.data.generatedResponse.includes("I'm unable to") || 
          paraphraseResponse.data.generatedResponse.includes('do not')|| 
          paraphraseResponse.data.generatedResponse.includes('don\'t')) {
          setSuccess(false);
      } else {
          setSuccess(true);
      }

      const updatedBotMessageData = {
        msg_text: paraphraseResponse.data.generatedResponse,
        msg_urls: pdfResponse.sources,
        msg_titles: pdfResponse.filenames,
        showFeedback: true 
      };
      setpdfMessages(prevMessages => {
        const updatedMessages = { ...prevMessages };
        updatedMessages[msg_index + 1] = updatedBotMessageData;
        return updatedMessages;
      });
      const messageData = {
        msg_user: userMessage,
        msg_bot_seaplane: pdfResponse.response,
        msg_bot_files: pdfResponse.filenames,
        msg_bot_paraphrased: paraphraseResponse.data.generatedResponse,
        ip_address: ipAddress,
        timestamp: serverTimestamp()
      };
      await addDoc(collection(db, "messages"), messageData);
    } else {
      console.error('Error getting paraphrase response.');
    }
  };
  const handleFeedback = async (msg, feedbackType, index) => {
    console.log(messages[index])
    console.log(messages[index-1])
    console.log(pdfMessages[index])
    try {
      await addDoc(collection(db, "feedback"), {
        msg_text: messages[index] || "",  
        feedback: feedbackType,
        timestamp: serverTimestamp(),
        ip_address: ipAddress || "", 
        user_msg: messages[index-1] || "",  
        paraphrased_response: pdfMessages[index].msg_text || "", 
        pdf_files: pdfMessages[index].msg_urls || []
        });

      setMessages(prevMessages => {
        return prevMessages.map(message => {
          if (message === msg) {
            return {
              ...message,
              feedbackGiven: true
            };
          }
          return message;
        });
      });
    } catch (error) {
      console.error('Error sending feedback: ', error);
    }
  };
  

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIpAddress();
  }, []);
  
  const handleKeyDown = (e) => {
    if (allowTagClick && userMessage && e.key === 'Enter') {
      sendMessage();
    }
  };

  const scrollToBottom = () => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  };

  const toggleTTS = () => {
    setTTSEnabled(!ttsEnabled);
  };

  const handleTTS = async (text) => {
    if (!ttsEnabled) return;
    text = text.replace(/COMMB/g, 'comb');
    text = text.replace(/OOH/g, 'O O H');
    try {
      const response = await fetch('https://ooh-gpt-2-0-tts-openai.onrender.com/synthesize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to generate speech');
      }
  
      const audioBlob = await response.blob();
      const audioUrl = URL.createObjectURL(audioBlob);
      const newAudio = new Audio(audioUrl);
      setCurrentAudio(false);
      if (currentAudio) {
        currentAudio.pause();
      }
      setCurrentAudio(newAudio);
      newAudio.play();  
      newAudio.addEventListener('ended', () => {
        setCurrentAudio(null);
      });
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const removeHideClass = (index) => {
    const target = document.querySelector(`.pdf-query-${index}`);
    if (target) {
      target.classList.remove('hide');
      const btn = document.querySelector(`button#btn${index}`);
      if (btn) {
        btn.classList.add('hide');
      } else {
        console.error('unable to find btn');
      }
    } else {
      console.error('unable to find target');
    }
  };
  
  const handlePDFSearch = (e) => {
    const btn = e.target.closest('button');
    btn.querySelector('p').classList.add('hide');
    const img = btn.querySelector('img');
    img.style.opacity = 1;
    const msg_id = parseInt(btn.id.replace('btn', ''), 10);
    btn.querySelector('.pdf-load').classList.remove('hide');
    const intervalId = setInterval(() => {
    removeHideClass(msg_id);
      const target = document.querySelector(`.pdf-query-${msg_id}`);
      if (target) {
        clearInterval(intervalId);
        if (success) {
          setBotState('success');
        } else {
          setBotState('error');
        }
        setTimeout(() => {
          setBotState('none');
        }, 5000);
      }
    }, 1000);
    setTimeout(() => {
      clearInterval(intervalId);
    }, 10000);
  };
  
  // ========================================
  // Section: Effects
  // ========================================

  useEffect(() => {
    return () => {
      clearInterval(typingIntervalId);
    };
  }, [typingIntervalId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const now = new Date();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const day = days[now.getDay()];
    const hours = now.getHours() % 12 || 12; 
    const minutes = now.getMinutes();
    const amPm = now.getHours() >= 12 ? 'PM' : 'AM';
    const formattedTimestamp = `${day} ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${amPm}`;
    setTimeStamp(formattedTimestamp);
    const TRACKING_ID = "G-ST9BCR1WKQ";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  // ========================================
  // Section: JSX Structure
  // ========================================

  return (
    <div className='chatbot'>
      <div className={`chatbox ${darkMode ? 'dark' : ''}`}>
        <div className="header">
          <img src={logo} alt="" />
          <div className="toggle-lang">
            <span className={lang === 'EN' ? 'selected' : ''} onClick={() => {setLang('EN')}}>EN</span>
            <span>|</span>
            <span className={lang === 'FR' ? 'selected' : ''} onClick={() => {setLang('FR')}}>FR</span>
          </div>
        </div>
        <div className='fixed'>
          <div className="timestamp">
            {timeStamp}
          </div>
          <div className="toggle-tts">
            <img src={darkMode ? darkSound : sound} alt="" />
            <div>            
              <input checked={ttsEnabled} onChange={toggleTTS} type="checkbox" id='tts' onClick={(e) => e.stopPropagation()}/>
              <label htmlFor="tts" className='button'></label>
            </div>
          </div>
        </div>
        <div className="conversation" ref={conversationRef}>
          <div className='bot'>
            {lang === 'EN' ? 'Hello, my name is BillBot, and I can answer questions about OOH. How can I help you?' :"Bonjour, je m'appelle BillBot, et je peux répondre aux questions sur les affichages extérieurs. Comment puis-je vous aider?"}
          </div>
          {/* <div className="suggestion">
            <p>{lang === 'EN' ? 'Other people are looking for...':'D\'autres personnes recherchent...'} </p>
            <div>
              {lang === 'EN' ? tags.map((tag, index) => (
                <p className={`tag ${allowTagClick ? '' : 'inactive'}`}  key={index} onClick={allowTagClick ? handleTagClick : () => {}}>{tag}</p>
              )) : tagsFR.map((tag, index) => (
                <p className={`tag ${allowTagClick ? '' : 'inactive'}`}  key={index} onClick={allowTagClick ? handleTagClick : () => {}}>{tag}</p>
              ))}
            </div>
          </div> */}
          {messages.map((msg, index) => (
            <div key={index} className={msg.type} >
              <p>{msg.msg_text}</p>
              {msg.type === 'bot' && (
                  <button id={"btn" + index} className={`pdf-btn hide`} onClick={handlePDFSearch}><img src={load} className='pdf-load hide' alt="" /><p className='slide'><img src={search} alt="" />{lang === 'EN'?'Click to search':"Rapport d'analyse"}</p>
                  </button>
              )}

              {msg.type === 'bot' && pdfMessages[index] && (
                <p className={`pdf-query-${index} hide`}>
                  {lang === "FR" ? "Voici ce que j'ai trouvé en lisant les publications de COMMB. " : "Here is what I found reading through COMMB publications. "}{pdfMessages[index].msg_text}{lang === "FR" ? " Vous pouvez trouver plus d'informations dans :":" You can find more information in: "}{' '}
                  {pdfMessages[index].msg_titles.split(',').map((filename, i) => {
                      const matchingReport = reports.find(report => report.filename === filename);
                      if (matchingReport) {
                        return (
                          <a
                            key={i}
                            className={`pdf-link ${darkMode ? 'dark-mode' : ''}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={pdfMessages[index].msg_urls.split(',')[i]}
                          >
                            {matchingReport.title}
                          </a>
                        );
                      } else {
              
                        return (
                          <a
                            key={i}
                            className={`pdf-link ${darkMode ? 'dark-mode' : ''}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={pdfMessages[index].msg_urls.split(',')[i]}
                          >
                            {filename}
                          </a>
                        );
                      }
                  })}
                </p>
                )}
                {msg.type === 'bot' && msg.showFeedback && (
                  <div className="feedback">
                    <span>
                     {msg.feedbackGiven ? "Thank you for your feedback." : "How would you rate BillBot's response? "}
                    </span>
                    {!msg.feedbackGiven && (
                      <div className="icons">
                        <div onClick={() => handleFeedback(msg, 'up', index)}>
                          <img src={up} alt="" />
                        </div>
                        <div onClick={() => handleFeedback(msg, 'down', index)}>
                          <img src={up} className='down' alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                )}
            </div>
          ))}
        </div>
        <div className="user-input">
          <img src={mode} onClick={() => setDarkMode(!darkMode)}  alt=''/>
          <input
            placeholder={lang === 'EN'? 'Type your message here...' : 'Tapez votre message ici..'}
            value={userMessage}
            onChange={e => setUserMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={sendMessage} className='send-button'><img src={send} alt="" /></button>
        </div>
      </div>    
    </div>
  );
}

export default Billbot;
